const RecentData =[
    {
        recentProductTitle:'Grass Cutter',
        recentProductPrice:'4,500.00',
        recentProductImage:'../cow-slider-image/healthy-cow-01.png',
    },
    {
        recentProductTitle:'Loader Scale',
        recentProductPrice:'6,500.00',
        recentProductImage:'../cow-slider-image/healthy-cow-01.png',
    },
    {
        recentProductTitle:'Weight Machine',
        recentProductPrice:'7,500.00',
        recentProductImage:'../cow-slider-image/healthy-cow-01.png',
    },
    {
        recentProductTitle:'Room Neet',
        recentProductPrice:'10,500.00',
        recentProductImage:'../cow-slider-image/healthy-cow-01.png',
    },
    {
        recentProductTitle:'Trabolin Light',
        recentProductPrice:'5,000.00',
        recentProductImage:'../cow-slider-image/healthy-cow-01.png',
    },
    {
        recentProductTitle:'Sprea Machine',
        recentProductPrice:'7,500.00',
        recentProductImage:'../cow-slider-image/healthy-cow-01.png',
    }
    ,
    {
        recentProductTitle:'Room Hitter',
        recentProductPrice:'8,000.00',
        recentProductImage:'../cow-slider-image/healthy-cow-01.png',
    },
    {
        recentProductTitle:'Milk Detector',
        recentProductPrice:'9,000.00',
        recentProductImage:'../cow-slider-image/healthy-cow-01.png',
    }
]
export default RecentData;