const BlogVideoData = {
    id:"Gw6Y_Nzff4O71E1f",
    title:"title1",
    id:"UoeTA0E7ZcQ",
    title:"title2",
    id:"2TkiYynpj14AoYda",
    title:"title3",
    id:"Gw6Y_Nzff4O71E1f",
    title:"title4",
    id:"CJTi7rwWApbW2_xM",
    title:"title5",
    id:"jP01tA219suex67g",
    title:"title6",
}
export default BlogVideoData;