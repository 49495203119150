import React from 'react'

function AllRecentProductDetails() {
    return (
        <>
            <a href='#' id='ProdViewButton' type='button' className="btn btn-sm btn btn-bg product-view-button"> All New Products</a>
        </>
    )
}

export default AllRecentProductDetails;
