const PopularCategoryData =[
    {
        categoryTitle:'Load Scale',
        categoryImage:'../product-category-image/loader-scale-cate.png',
    },
    {
        categoryTitle:'Weight Machine',
        categoryImage:'../product-category-image/Weight-Scales.png',
    },
    {
        categoryTitle:'Grass Cutter',
        categoryImage:'../product-category-image/grass-cutters.png',
    },
    {
        categoryTitle:'Room Hitter',
        categoryImage:'../product-category-image/room-hitters.png',
    }
]
export default PopularCategoryData;