const Data=[
    {
        name:"Mr.Jhon",
        designation:"CEO ,Trodar Limited",
        image:"../testimonial-image/testimonial1.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Fridol",
        designation:"Chairman ,Arova Limited",
        image:"../testimonial-image/testimonial2.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Mr.Alex",
        designation:"MD ,Agro Limited",
        image:"../testimonial-image/testimonial3.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Mr.Alex",
        designation:"MD ,Agro Limited",
        image:"../testimonial-image/testimonial3.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
]
export default Data;