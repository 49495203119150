const AnotherData=[
    {
        name:"Flexdar",
        designation:"CEO ,Presto Limited",
        image:"../testimonial-image/testimonial4.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Shelus",
        designation:"MD ,Torster Limited",
        image:"../testimonial-image/testimonial5.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Richsuel",
        designation:"Founder ,Progota Limited",
        image:"../testimonial-image/testimonial6.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Richsuel",
        designation:"Founder ,Progota Limited",
        image:"../testimonial-image/testimonial6.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    }
]
export default AnotherData;