const imageSlider = [
    {
        url:'../services-image/counselling.jpg',
        title:'Doctor Counselling',
        body:'Doctor observe to case history in cow.'
    },
    {
        url:'../services-image/tretment.jpg',
        title:'Doctor Treatment',
        body:'Doctor treat to cow with concern to diseases.'
    },
    {
        url:'../services-image/medicine_self.jpg',
        title:'Medicine',
        body:'Doctor prescribe for diseases.'
    },
    {
        url:'../services-image/dariry-fram.jpg',
        title:'Dariry Farm',
        body:'Animal dariry farm product.'
    },
]
export default imageSlider;