import React from 'react'

function TopBestSellingButton() {
    return (
        <>
            <a href='#' id='TopSellingButton' type='button' className="btn btn-sm btn btn-bg product-view-button"> Top 15</a>
        </>
    )
}

export default TopBestSellingButton;
