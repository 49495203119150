const AlsoData=[
    {
        name:"Mr.Jhon",
        designation:"Founder ,Trodar Limited",
        image:"../testimonial-image/testimonial1.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Fridol",
        designation:"Owner ,Arova Limited",
        image:"../testimonial-image/testimonial2.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Mr.Alex",
        designation:"MD ,Agro Limited",
        image:"../testimonial-image/testimonial3.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
    {
        name:"Mr.Alex",
        designation:"MD ,Agro Limited",
        image:"../testimonial-image/testimonial3.jpg",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
    },
]
export default AlsoData;